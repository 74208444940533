@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap);
@font-face {
  font-family: 'GothamMedium';
  src: local('Gotham_Medium'), url(/static/media/Gotham_Medium.bcd733e6.otf) format('opentype');
}

html, body, #root {
  font-family: "GothamMedium, Arial";
  background-size: contain;
  padding: 0px !important;;
  margin: 0px auto !important;;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.MuiTypography-body1 {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.5;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #000;
}
div.MuiCardMedia-root.makeStyles-media-23 {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: padding-box;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

